import {createApp} from "vue";
import Notification from "@kyvg/vue3-notification";
import Gallery from "../vue/Gallery.vue";
import Dialog from "../vue/plugins/dialog.js";

createApp(Gallery, {
    admin: false,
    scrollContainer: document.getElementById('main-content'),
})
    .use(Notification)
    .use(Dialog)
    .mount('#gallery');
